.Layout-container {
  height: 100%;
}
.Layout-container aside {
  height: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
}
.header .avatar {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar {
  cursor: pointer;
}
