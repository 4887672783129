.userdetail {
  height: 100%;
  overflow-y: auto;
}

.pie,
.bar {
  height: 100% !important;
  width: 100% !important;
}
.title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 20px;
  font-style: bold;
}
.user_infor .ant-card {
  height: 100%;
}
